import { useState } from "react";
import { MdAdd, MdRemove } from "react-icons/md";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <>
      {open === id ? (
        <MdRemove className="h-[49px] w-[49px] p-2 rounded-lg text-white bg-gradient-to-tr from-hotPink via-purplish to-accent  transition-all duration-500 hover:-translate-y-1 -mt-[10px] -ml-8" />
      ) : (
        <MdAdd className="h-[49px] w-[49px] p-2 rounded-lg text-white bg-jacarta-800  transition-all duration-500 hover:-translate-y-1 -mt-[10px] -ml-8" />
      )}
    </>
  );
}

const QAccordion = ({ array=[] , NoBorderBG}) => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mb-14">
      {array &&
        array.map((item) => {
          return (
            <div
              key={item.id}
              className="flex  gap-6  mb-10 rounded-[12px] py-[18px] md:p-[43px] border dark:border-gray-200"
            >
              <Accordion
                className=""
                open={open === item.id}
                icon={<Icon id={item.id} open={open} />}
              >
                <AccordionHeader
                  className={`accordion-button flex justify-between px-4 py-3 text-left font-display text-jacarta-900  dark:text-white text-[16px] md:text-[24px] ${NoBorderBG ? '' : 'bg-white dark:bg-jacarta-900'}`}
                  onClick={() => handleOpen(item.id)}
                >
                  <p className="max-w-[80%] lg:max-w-full">{item.question}</p>
                </AccordionHeader>
                <AccordionBody className={ `accordion-body p-4 dark:border-jacarta-600 h-full text-base ${NoBorderBG ? '' :' bg-white dark:bg-jacarta-900 '}`}>
                  {item.answer}
                </AccordionBody>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

export default QAccordion;
