import Image from "next/image";
import Fade from "react-reveal/Fade";
import SearchBar from "./Searchbar";
import TLD from "./TLD";
import Link from "next/link";

const hero = () => {
  return (
    <section className=" pb-0 md:pb-[220px]">
      <div className="relative container pt-[116px] md:pt-[98px]  lg:pt-[118px] ">
        <div className="relatve">
          <div className=" h-full mx-auto max-w-[920px] relative">
            <div className="h-full items-center gap-4 md:grid-cols-12">
              <div className="lg:px-6 h-full text-center pt-10 md:items-start  md:pt-20 xl:col-span-4">
                <Fade ssrReveal bottom>
                  <h1 className="text-jacarta-900 font-light font-display mt-[20px] mb-4 text-center text-[25px] md:text-[44px] dark:text-white  lg:text-[64px] xl:text-[68px] max-w-[500px] md:max-w-[600px] lg:max-w-[9200px] mx-auto leading-tight	">
                  Activate Your Audience with Branded Crypto Wallets & <br/> <span className="animate-gradient">Web3 Domains</span>
                  </h1>
                </Fade>
                <Fade ssrReveal bottom delay={350}>
                  <p className="mb-[28px] text-center text-[16px] md:text-[18px] font-normal max-w-[500px] md:max-w-[450px] lg:max-w-[470px] mx-auto">
                  Seamlessly onboard users with no-code links, deliver personalized wallet experiences, and unlock revenue with Web3 domains and NFTs—all in one platform.
                  </p>
                </Fade>

                <SearchBar main={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <TLD />
      <div
        className={`  h-[400px] md:h-[700px] lg:h-[750px] relative -mt-[25rem] md:-mt-[50%] lg:-mt-[20rem] z-[-1] top-0 dark:mix-blend-lighten mix-blend-difference`}
      >
        <div className="relative w-full h-full z-[-1] animate-fade">
          <Image
            fill
            sizes="100vw"
            src="/images/hero/hero-space.png"
            alt="hero-background"
            className=" z-[-1] object-cover hidden dark:block"
          />
        </div>
      </div>
      <div className="md:-mt-[200px] lg:-mt-[426px]">
        <div className="relative container   ">
          <Fade ssrReveal bottom delay={350}>
            <div className="flex flex-col md:flex-row gap-6 md:gap-8 justify-center pt-6">
              <Link
                href="/domains"
                className="min-w-[230px] items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center"
              >
                Start Now
              </Link>
              <Link
                href="/community"
                className="min-w-[230px] items-center hidden lg:flex justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300"
              >
                Explore Our Tools
              </Link>
            </div>
          </Fade>
        </div>
        {/* <VideoIntro /> */}
      </div>
    </section>
  );
};

export default hero;
