import React, { useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { MdDomain } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { Fade } from "react-reveal";
import Link from "next/link";

const PreferToSay = () => {
  const [activeCard, setActiveCard] = useState(0);

  const cardData = [
    {
      icon: MdDomain,
      title: "Set Domain Pricing",
      description: "Brands can set custom prices for sublevel domains from their TLD, driving revenue directly from their community.",
      // link: "Search now"
    },
    {
      icon: BsFillSendFill,
      title: "Send NFTs Easily",
      description: "Send free or paid NFTs—digital or phygital—to newly onboarded wallets, creating engagement and increasing revenue.",
      // link: "Set up now"
    },
    {
      icon: IoLocationSharp,
      title: "Track & Earn",
      description: "Monitor user wallets, profiles, and sales with in-depth analytics, and get payouts easily through our Stripe integration.",
      // link: "Earn today"
    }
  ];

  return (
    <>
      <div className="container py-[68px]  md:py-[98px]  lg:py-[118px]  ">
        <Fade ssrReveal>
          <div className="w-full max-w-[40rem] mx-auto text-jacarta-900 dark:text-white text-center">
            <h2 className="text-center text-[24px] md:text-[32px] lg:text-[38px] font-medium px-14">
              Want more customization? Try the community tools!
            </h2>
            <p className="mt-4">
              Brands can price sublevel domains, send NFTs, and track
              performance through analytics, with payouts via Stripe.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  py-10 rounded-sm bg-white dark:bg-jacarta-900">
            {cardData.map((card, index) => (
              <div
                key={index}
                onClick={() => setActiveCard(index)}
                className={`px-[24px] py-[50px] lg:px-[24px] lg:py-[97px] dark:border-jacarta-600 border-jacarta-900 mb-5 overflow-hidden rounded-lg border cursor-pointer transition-all duration-300 ${
                  activeCard === index
                    ? "bg-gradient-to-tr from-hotPink via-purplish to-accent"
                    : ""
                }`}
              >
                <card.icon 
                  className={`h-8 w-8 mb-6 ${
                    activeCard === index 
                      ? "text-white" 
                      : "text-jacarta-800 dark:text-white"
                  }`} 
                />
                <p className={`text-[28px] mb-6 font-medium ${
                  activeCard === index 
                    ? "text-white" 
                    : "dark:text-white text-jacarta-900"
                }`}>
                  {card.title}
                </p>
                <p className={`text-md mb-6 ${
                  activeCard === index 
                    ? "text-white" 
                    : "dark:text-white text-jacarta-900"
                }`}>
                  {card.description}
                </p>
                {/* Uncomment if you want to add links back
                <Link href="/" className="text-md text-white">
                  {card.link}
                </Link> */}
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </>
  );
};

export default PreferToSay;
