import React from "react";
import { Hero } from "../../components/component";
// import Faq from "./faq";
import Invest from "./invest";
// import Support from "./support";
import EcoSystem from "./ecoSystem";
import HowItWorks from "./HowItWorks";
import ContactUs from "./contactUs";
import PreferToSay from "../../components/PreferToSay";
import Questions from "./questions";
import MajorFeatures from "../../components/features/majorFeatures";
import Testimonoals from "./testimonials";
import Divider from "../../components/common/Divider";
import Pricing from "./Pricing";
// import WalletDropdown from "../../components/common/WalletMenu";
// import { useActiveAccount } from "thirdweb/react";



const homeFaq = [
  {
    id: 1,
    question: " What exactly is a Web3 domain?",
    answer:
      " A Web3 domain, also known as a decentralized domain or blockchain domain, is a unique identifier on a blockchain that replaces traditional URLs. It enables users to access decentralized websites, apps, and content without relying on traditional DNS systems.",
  },
  {
    id: 2,
    question: "What are the advantages of using a Web3 domain?",
    answer:
      "Web3 domains offer several benefits, including enhanced security, censorship resistance, and ownership control. They are not controlled by centralized authorities, reducing the risk of domain hijacking or censorship. Additionally, they can simplify cryptocurrency transactions by allowing users to send funds to human-readable addresses.",
  },
  {
    id: 3,
    question: " What are the capabilities of Web3 domains?",
    answer:
      "Web3 domains provide various capabilities, including linking to decentralized websites (dApps), receiving cryptocurrencies directly, and enabling personalized subdomains. They also offer a seamless way to interact with blockchain-based services, NFT marketplaces, and other decentralized applications.",
  },
  {
    id: 4,
    question: "Are there any renewal fees for Web3 domains?",
    answer:
      "Unlike traditional domains, NetZylo Web3 domains don't have renewal fees. Once you've acquired a Web3 domain, you have ongoing ownership without the need to pay annual renewal fees, making them a cost-effective choice.",
  },
];

const Home_1 = () => {
  return (
    <main className="overflow-hidden">
      <Hero />
   
      <Divider  />
      <HowItWorks />
      <EcoSystem />
      <Divider  className="container"/>
      <MajorFeatures />

      <Divider  className="container"/>
      <PreferToSay />
      <Divider  className="container"/>   
      <Pricing home={true}/>  
      <Divider  className="container"/>   
      {/* <Testimonoals />
      <Divider  className="container"/> */}

      <Invest />
      <Divider  className="container"/>
      <Questions  data={homeFaq}/>
      <Divider  className="container"/>
      <ContactUs />

    </main>
  );
};

export default Home_1;
