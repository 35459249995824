import React from 'react'
import TLDs from './TLDs'
import Fade from 'react-reveal/Fade';

function TLD() {
    return (
        <Fade ssrReveal>
            <div className=" dark:bg-jacarta-900 relative">

                <div>
                    <div className="hidden dark:block absolute right-0 top-0 bottom-0 left-auto z-[10] w-[30%]" style={{
                        backgroundImage: 'linear-gradient(270deg, #07091b, rgba(7, 9, 27, 0))',
                    }}
                    ></div>

                    <div className="hidden dark:block absolute right-auto top-0 bottom-0 left-0  z-[10] w-[30%]" style={{
                        backgroundImage: 'linear-gradient(90deg, #07091b, rgba(7, 9, 27, 0))',
                    }}></div>



                    <div className=" animate-marquee space-x-4 my-6 flex relative">
                        <TLDs />
                    </div>
                </div>

            </div>
        </Fade>
    )
}

export default TLD