import Image from "next/image";
import React from "react";
import { Fade } from "react-reveal";
const data = [
  {
    title: "Seamless Onboarding Experience",
    desc: "NetZylo made setting up our Web3 domain and onboarding users seamless. The shareable profile page has driven tons of engagement, and the NFT minting tools are a game-changer!",
    name: "Sarah L.",
    designation: "Boutique Brand Owner",
  },
  {
    title: "Driving New Revenue Channels",
    desc: "NetZylo's platform allowed us to create phygital experiences for our customers. Sending branded NFTs has been a unique way to connect with our community and grow our brand.",
    name: " Mark T.",
    designation: " Event Organizer",
  },
  {
    title:"Elevating Brand Engagement",
    desc: "As a small business, I love how easy it was to get started for free. The dashboard gives us insights into customer activity, and the payouts from SLD sales are a great bonus!",
    name: " Emily R.",
    designation: "TLD Owner",
  },
];
function Testimonoals() {
  return (
    <section>
      <div className="container py-[68px]  md:py-[98px]  lg:py-[118px]  ">
        <Fade ssrReveal bottom cascade delay={350}>
          {" "}
          <div className="mb-10  max-w-[700px] mx-auto w-full  ">
            <h2 className="mb-4 text-center font-display text-3xl text-jacarta-900 dark:text-white">
              What Our Customers Are Saying
            </h2>
            <p className="text-center px-10">
              See how our platform helps drive engagement, growth, and revenue.
            </p>
          </div>
        </Fade>
        <div className="grid md:grid-cols-2 gap-0">
          {/* <Fade left delay={350}> */}
            <div className="lg:mr-14 ">
              {data.map((item) => {
                return (
                  <div className="py-10 border border-x-0 border-t-0 dark:border-gray-200 last:border-0">
                    <h2 className="text-lg lg:text-xl mb-4 font-medium">
                      {item.title}
                    </h2>
                    <p className="text-base lg:text-[18px] mb-7 !text-[#b5b6bb]">
                      {item.desc}
                    </p>
                    <p className="text-base lg:text-[18px] mb-2">{item.name}</p>
                    <p className="inline-block text-base lg:text-[18px] ">
                      {item.designation}
                    </p>
                  </div>
                );
              })}
            </div>
          {/* </Fade>
          <Fade right delay={350}> */}
            <div className="border dark:border-gray-200 rounded-large pt-[56px] pr-[48px] pb-[23px] pl-[30px] relative overflow-hidden h-[750px] lg:h-full">
              <div>
                <h2 className="text-[26px] lg:text-[38px] mb-5 font-medium">
                Cutting-Edge Web3 Tools
                </h2>
                <p className="mb-8 text-base lg:text-[18px] leading-7 !text-[#b5b6bb]">
                  NetZylo helped us create a truly unique digital identity for
                  our brand. The process was simple, and the NFT tools have
                  opened new opportunities for customer engagement and revenue.
                </p>
                <p className="text-base lg:text-[18px] mb-3">Alex M</p>
                <p className="text-base lg:text-[18px]">Creative Director</p>
              </div>
              <div
                className={` w-full absolute  z-[10] left-0 top-[50%] mix-blend-color-dodge`}
              >
                <div className="relative  h-[600px] w-[1000px]  animate-fade">
                  <Image
                    fill
                    sizes="100vw"
                    src="/images/hero2.webp"
                    alt="dots-background"
                    className="object-cover "
                  />
                </div>
              </div>
              <div className="absolute -right-16 bottom-4 z-[20]">
                <Image
                  src="/images/aboutC.svg"
                  width={506}
                  height={463}
                  className="object-contain w-[350px] lg:w-[506px]"
                />
              </div>
            </div>
          {/* </Fade> */}
        </div>
      </div>
    </section>
  );
}

export default Testimonoals;
