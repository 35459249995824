import Link from 'next/link'
import React from 'react'

const tlds = [
    ' .tokenz',
    '.brand',
    '.Vibes',
    '.Zestify',
    '.cryptobull',
    '.Pulse',
    '.bitwallet',
    '.Deluxe',
    '.Glow',
    '.rarenft',
    '.Rise',
    '.Chic',
    '.Flavor',
    '.Vivid',
    '.gamerpro',
    '.Glo',
    '.Vibe',


    ' .tokenz',
    '.brand',
    '.Vibes',
    '.Zestify',
    '.cryptobull',
    '.Pulse',
    '.bitwallet',
    '.Deluxe',
    '.Glow',
    '.rarenft',
    '.Rise',
    '.Chic',
    '.Flavor',
    '.Vivid',
    '.gamerpro',
    '.Glo',
    '.Vibe',
]



function TLDs() {
    return (
        <>
            {tlds.map((item) => {
                return (
                    <Link key={item} href={`/domains?q=${item}`} className="min-w-[45%] sm:min-w-[25%] md:min-w-[23%] lg::min-w-[16.4%] bg-white shadow-lg  dark:bg-gray-200 dark:text-white text-jacarta-900 rounded-md p-4 hover:bg-gradient-to-tr hover:from-hotPink hover:via-purplish hover:to-accent transition-all duration-500 cursor-pointer group">
                        <p className=' text-jacarta-9000 group-hover:text-white text-base md:text-lg font-semibold  text-center'>{item}</p>
                    </Link>

                )
            })}
        </>


    )
}

export default TLDs