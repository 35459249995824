import React from "react";
import { Fade } from "react-reveal";
import QAccordion from "./accoedion";

const Questions = ({ border, data = [], NoBorder }) => {
  return (
    <>
      <div
        id="faq"
        className={`relative container  py-[68px] md:py-[98px] lg:py-[118px] 
        ${
          NoBorder && '!py-16'
        }
        `}
      >
        <Fade ssrReveal>
          <div className="grid lg:grid-cols-3 gap-10">
            <div className="col-span-1 ">
              <div className="">
                <h1 className="text-[24px] md:text-[32px] lg:text-[38px] text-center lg:text-start font-medium mb-[16px]">
                  Frequently asked questions
                </h1>
                {!border && (
                  <p className="text-[16px] md:text-[18px] mb-[40px] text-center lg:text-start ">
                    Discover Web3 Domains: FAQs on benefits, ownership, and
                    more.
                  </p>
                )}
              </div>
            </div>

            <div className="lg:col-span-2">
              <QAccordion array={data} NoBorderBG={NoBorder} />
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
};
export default Questions;
