import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Fade } from "react-reveal";

function MajorFeatures({ NoBorder }) {
  return (
    <section
      className={`container  ${
        NoBorder === true ? " py-16" : "py-[68px] md:py-[98px]  lg:py-[118px] "
      } `}
    >
      <div>
        <div className=" dark:bg-jacarta-900  text-center  ">
          <div className="mb-10  max-w-[700px] mx-auto w-full  ">
            <h2 className="mb-4 text-center font-display text-3xl text-jacarta-900 dark:text-white">
              Get Started in 3 Easy Steps
            </h2>
            <p className="text-center px-10">
              Claim your domain, create your profile, and start managing your
              assets and community with ease.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 md:gap-10 lg:grid-cols-3">
            <Fade ssrReveal bottom cascade delay={350}>
              <div className="w-full lg:w-[100%] md:px-20 lg:px-2">
                <Image
                  width={351}
                  height={351}
                  className=" mx-auto z-[-1]"
                  alt="Readable-Wallets"
                  src="/images/features/f-1.png"
                />
                <div className="px-0">
                  <h2 className="mb-5 font-medium text-[28px]">
                    Create Your Wallet{" "}
                  </h2>
                  <p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
                    Easily set up your crypto wallet with a simple email
                    verification.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-[100%] md:px-20 lg:px-2">
                <Image
                  width={351}
                  height={351}
                  className=" mx-auto  z-[-1]"
                  alt="Blockchain-Domain"
                  src="/images/features/f-3.png"
                />
                <div className="px-0">
                  <h2 className="mb-5 font-medium text-[28px]">
                    Mint Your Domain
                  </h2>
                  <p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
                  Set up your shareable profile to onboard users with email-generated wallets, sell subdomains, and boost brand awareness and revenue.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-[100%] md:px-20 lg:px-2">
                <Image
                  width={351}
                  height={351}
                  className=" mx-auto  z-[-1]"
                  alt="Web3-Identity"
                  src="/images/features/f-2.png"
                />
                <div className="px-0">
                  <h2 className="mb-5 font-medium text-[28px]">
                    Engage & Earn
                  </h2>
                  <p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
                  Use our tools to launch NFTs and phygital engagements, build
                    your community, and drive revenue.  
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          <div className="flex items-center justify-center gap-8 mt-20">
            <Link
              href="/domains"
              className="items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center w-full md:w-[20%]"
            >
              Start now
            </Link>
            <Link
              href="/community"
              className={`${
                NoBorder ? "hidden" : "hidden lg:flex"
              } items-center  justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300  w-full md:w-[20%]`}
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MajorFeatures;
