// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar } from "swiper";
// import "swiper/css";
// import "swiper/css/navigation";


const BidsCarousel = () => {

  return (
    <></>
  );
};

export default BidsCarousel;
