import React from "react";
import { useTheme } from "next-themes";
import Image from "next/image";
import { Fade } from "react-reveal";
import Link from "next/link";
const Invest = () => {
  const { theme } = useTheme();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <section className="container relative py-[68px] md:py-[98px]  lg:py-[118px] ">
      <Fade ssrReveal delay={350}>
        <div className="  relative rounded-md px-[24px] md:px-[40px] lg:px-[100px] py-[80px] md:py-[140p] lg:py-[130px] text-center  bg-transparent">
          <div className="absolute  top-0 left-0 h-full w-full rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent z-[1]"></div>

          <div className="md:max-w-[600px] lg:max-w-[750px] mx-auto relative z-[2]">
            <Fade ssrReveal bottom delay={300}>
              <h2 className="mb-6 text-center font-display text-[25px] md:text-[44px] lg:text-[64px] font-normal text-white ">
              Take the Next Step
              </h2>

              <p className="text-[16px] md:text-[18px] mb-12 text-white">
              Unlock new opportunities with web3 domains, NFTs, and secure digital asset management. Get started today and elevate your brand’s digital presence.
              </p>

              <div className="flex flex-col md:flex-row gap-6 md:gap-10 justify-center relative z-[10]">
                <button
                 onClick={scrollToTop}
                  className="items-center justify-center text-[16px] font-bold md:text-[18px] py-[22px] px-[32px] md:py-[24px] md:px-[38px] button rounded-md  bg-white border border-jacarta-100 relative z-[10] transition-all duration-500 hover:-translate-y-1"
                >
                  <span className="bg-gradient-to-tr from-hotPink via-purplish to-accent  bg-clip-text text-transparent ">
                    Search now
                  </span>
                </button>
                <Link
                  href="/domains"
                  className="items-center justify-center rounded-md border border-gray-100 dark:bg-jacarta-800 text-[16px] font-bold md:text-[18px] py-[22px] px-[32px] md:py-[24px] md:px-[38px] relative z-[10] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white hover:bg-jacarta-base cursor-pointer dark:hover:bg-white dark:hover:text-gray-300"
                >
                  Learn more
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </Fade>
      <div
        className={`w-full absolute top-[30%] left-0 z-[1] mix-blend-color-dodge pointer-events-none`}
      >
        <div className="relative h-[300px] w-[1200px] animate-fade pointer-events-none">
          <Image
            fill
            sizes="100vw"
            src="/images/hero2.webp"
            alt="dots-background"
            className="object-cover pointer-events-none"
          />
        </div>
      </div>
    </section>
  );
};

export default Invest;
